import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  .post {
    margin: 0 auto;
    max-width: 98rem;
    overflow: hidden;
    width: 100%;
    &__meta-info {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1rem;
      position: relative;
      h2 {
        color: var(--text);
        font-family: var(--font-heading);
        font-size: 3.5rem;
        font-weight: bold;
        letter-spacing: initial;
        line-height: 4rem;
        max-width: 84rem;
        text-transform: initial;
        width: 100%;
      }
      time {
        color: var(--text);
        font-weight: 400;
        margin-top: 1rem;
        width: 100%;
      }
      i {
        color: var(--primary);
        font-size: 3rem;
        margin: .525rem 1rem 0 0;
      }
      .post__meta-social {
        align-items: flex-start;
        display: flex;
        margin: 0 0 0 auto;
        a {
          color: var(--primary);
          align-items: center;
          border-radius: 5rem;
          display: flex;
          justify-content: center;
          text-decoration: none;
          padding: 1rem;
          &:hover {
            filter: brightness(1.1);
          }
          i {
            color: white;
            transform: translateX(1px);
            margin: 0;
          }
        }
        .facebook {
          background-color: #3b5998;
          margin-right: 1rem;
        }
        .twitter {
          background-color: #1da1f2;
        }
      }
      .post__meta-tags {
        color: var(--text);
        font-weight: 400;
        margin-top: 1rem;
        width: 100%;
        a {
          color: var(--primary);
          text-transform: capitalize;
          &:not(:last-of-type) {
            &:after {
              content: ",";
            }
          }
          &:hover {
            color: var(--text);
          }
        }
      }
    }
    iframe {
        height: calc(100vw * (9 / 16));
        width: calc(100vw * (16 / 9));
        margin: 2rem 0;
        max-height: calc(98rem * (9 / 16));
        max-width: 100%;
      }
    watch {
      color: var(--text);
      font-family: var(--font-body);
      line-height: 1.5;
      margin: 5rem auto;
      max-width: 78rem;
      padding: 0 1rem;
      .table-wrapper {
        overflow-x: scroll;
      }
      table {
        background-color: #E9E9E9;
        border-collapse: initial;
        border-spacing: 0;
        color: #000;
        font-size: 1.4rem;
        margin: 3rem 0;
        width: 100%;
        tr {
          position: relative;
          &:nth-child(odd) {
            td {
              background-color: #fff;
            }
          }
          &:nth-of-type(even) {
            td {
              background-color: #e9e9e9;
            }
          }
          td {
            // background-color: #050f1c;
            border: 1px solid #33435a;
            min-width: 12.75rem;
            text-align: center;
            padding: 1.25rem;
            white-space:normal;
            &:first-of-type {
              //background-color: #050f1c;
              box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.35);
              border-right: 2px solid #33435a;
              height: 100%;
              position: sticky;
              left: 0;
              top: 0;
              z-index: 11;
            }
          }
          &:first-of-type {
            font-weight: bold;
            text-transform: uppercase;
            td:first-of-type {
              background-color: var(--primary);
            }
          }
        }
      }
      p {
        color: var(--text);
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8;
        margin: 0 0 2rem;
      }
      blockquote {
        border-left: .5rem solid #ffb739;
        margin: 0 5rem;
        padding-left: 2.5rem;
        position: relative;
        &:before {
        content: '“';
        color: #ffb739;
        display: block;
        font-size: 8rem;
        left: 2.25rem;
        position: absolute;
        top: 2.25rem;
        }
        strong, b {
          color: #ffb739;
          font-weight: 100;
        }
      }
      img {
        height: auto;
        // margin-left: -5rem;
        position: relative;
        &.size-full {
          width: 100%;
          height: auto;
        }
      }
      ul {
        margin-left: 3rem;
        line-height: 1.8;
        font-size: 1.6rem;
        font-weight: 100;
        margin-bottom: 2rem;
        li {
          font-weight: 400;
          line-height: 2;
          list-style-type: unset;
        }
      }
      ol {
        margin-left: 3rem;
        line-height: 1.8;
        font-size: 1.6rem;
        font-weight: 100;
        margin-bottom: 2rem;
        li {
          font-weight: 400;
          line-height: 2;
          list-style-type: decimal;
        }
      }
      strong {
        font-weight: bold;
      }
    }
  }
 padding-top: 0;
 h1, h2, h3, h4, h5, h6  {
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}
h1 {
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 300;
}
h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
}
h3 {
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 600;
}
h4 {
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 3px;
}
h5 {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 3px;
  // line-height: 2rem;
}
h4, h5 {
  border-left: 4px solid var(--primary);
  padding-left: 1rem;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
/* li {
  list-style: none;
  position: relative;
} */
ol {
  margin: 0px 0px 0 -20px;
}
a {
  text-decoration: none;
  color: var(--primary);
}
`;